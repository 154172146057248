import {Routes} from '@angular/router';

export const routes: Routes = [
  {
    path: '',
    loadComponent: () => import('./components/layout/layout.component').then((c) => c.LayoutComponent),
    children: [
      {
        path: 'checkout',
        loadComponent: () => import('./pages/checkout-page/checkout-page.component').then((c) => c.CheckoutPageComponent),
      },
      {
        path: 'result',
        loadComponent: () => import('./pages/result-page/result-page.component').then((c) => c.ResultPageComponent),
      },
    ]
  }
];
