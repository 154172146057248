import {APP_INITIALIZER, ApplicationConfig, provideZoneChangeDetection} from '@angular/core';
import {provideRouter} from '@angular/router';

import {routes} from './app.routes';
import {provideNgxStripe} from 'ngx-stripe';
import {HttpClient, provideHttpClient} from '@angular/common/http';
import {ConfigService} from './services/config.service';
import {firstValueFrom, tap} from 'rxjs';
import {provideAnimations} from '@angular/platform-browser/animations';

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({eventCoalescing: true}),
    provideRouter(routes),
    provideNgxStripe(),
    provideHttpClient(),
    provideAnimations(),
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      multi: true,
      deps: [HttpClient, ConfigService],
    },
  ]
};

function initializeApp(httpClient: HttpClient, configService: ConfigService) {
  return (): Promise<any> =>
    firstValueFrom(
      httpClient
        .get(`/config/environment.json`)
        .pipe(
          tap(environment => configService.environment = environment)
        )
    );
}
